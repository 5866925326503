<template lang="pug">
.brand-themes-card(
  v-if="(theme.thumbnailV2 && theme.thumbnailV2.length) || theme.img"
  :class="classes(theme)"
  @click="onSeeAll"
)
  img(:src="imageProperty")
</template>

<script>
  export default {
    props: {
      theme: {
        type: Object,
        default: () => {},
      },
      name: { type: String, required: true },
      to: { type: [String, Object] },
      lg: { type: Boolean, default: false },
      needToTrack: { type: Boolean, default: true },
    },
    data() {
      return {
        loaded: false,
      };
    },
    computed: {
      imageProperty() {
        if (this.theme.thumbnailV2) return this.theme.thumbnailV2;

        return this.theme.img;
      },
    },
    methods: {
      classes(theme) {
        return {
          [theme.name]: true,
          'brand-themes-card-lg': this.lg,
        };
      },
      onSeeAll() {
        if (this.needToTrack) {
          this.$emit('track', { setting: 'theme_open', options: this.name });
        }
        this.$emit('navigateWithFilterReset', this.to);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .brand-themes-card
    position: relative
    border-radius: 20px
    aspect-ratio: 562/340
    height: auto
    border: 1px solid #E4E3E5
    overflow: hidden
    cursor: pointer
    img
      object-fit: cover
      width: 100%
      height: auto
      transition: all .3s
    @media (pointer: fine) and (hover: hover)
      &:hover
        img
          transform: scale(1.06)
</style>
